/* this file is transformed by vux-loader */
/* eslint-disable */
export default {
  props: {
    bpmName: String,
    createTime: String,
    subject: String,
    status: String,
    nodeName: String
  },
  components: {},
  name: 'instPanelStyle',
  data() {
    return {
      question: [],
      data: []
    };
  },
  created() {
    this.question = this.$parent.question;
  },
  methods: {
    getStatusHtml() {
      var str = "";
      switch (this.status) {
        case "RUNNING":
          str = "<span class='runing'>运行中</span>";
          break;
        case "SUCCESS_END":
          str = "<span class='finish'>结束</span>";
          break;
        case "ABORT_END":
          str = "<span class='abort'>异终止</span>";
          break;
        case "DISCARD_END":
          str = "<span class='discard'>作废</span>";
          break;
      }
      return str;
    },
    getFirstLetter() {
      if (!this.bpmName) return "";
      var str = this.bpmName.substr(0, 1).toUpperCase(),
        textColor = '';
      switch (this.status) {
        case "RUNNING":
          textColor = "<span class='runing-bg'>" + str + "</span>";
          break;
        case "SUCCESS_END":
          textColor = "<span class='finish-bg'>" + str + "</span>";
          break;
        case "ABORT_END":
          textColor = "<span class='abort-bg'>" + str + "</span>";
          break;
        case "DISCARD_END":
          textColor = "<span class='discard-bg'>" + str + "</span>";
          break;
      }
      return textColor;
    },
    getNodeHtml() {
      var textColor = '';
      switch (this.status) {
        case "RUNNING":
          textColor = 'runing1';
          break;
        case "SUCCESS_END":
          textColor = 'finish1';
          break;
        case "ABORT_END":
          textColor = 'abort1';
          break;
        case "DISCARD_END":
          textColor = 'discard1';
          break;
      }
      return textColor;
    }
  },
  mounted() {}
};