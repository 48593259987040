/* this file is transformed by vux-loader */
/* eslint-disable */
import router from "@/router/index";
export default {
  name: "Move",
  data() {
    return {
      index: 0,
      lock: true,
      showIndex: 1,
      itemLen: 4,
      leftDis: 0,
      transition: ".4s",
      customW: 0,
      //屏幕宽度
      touchX: 0,
      touchY: 0,
      touchT: null,
      moveX: 0,
      //移动距离
      op: 1,
      //透明度
      showIcon: -1,
      mes: {} //数据
    };
  },

  props: {
    listData: Object,
    otherPageData: Object,
    tab: {
      type: Boolean,
      default: false
    },
    doubleIcon: {
      type: Boolean,
      default: false
    },
    goBackIcon: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hideBar() {
      return this.$store.state.pageModule.keyboard;
    },
    vuexIndex() {
      return this.$store.state.pageModule.moveIndex;
    }
  },
  watch: {},
  methods: {
    touchSwiper(ev) {
      //			this.touchX = ev.changedTouches[0].clientX;
      //			this.touchY = ev.changedTouches[0].clientY;
      this.transition = "none";
      this.touchT = new Date();
    },
    moveSwiper(index, ev) {
      return;
      if (!this.lock) {
        this.leftDis = this.customW * this.index;
        return;
      } else {
        this.moveX = ev.changedTouches[0].clientX;
        var diff = this.moveX - this.touchX;
        if (Math.abs(diff) < Math.abs(ev.changedTouches[0].clientY - this.touchY)) {
          return this.lock = false;
        } else if (diff > 0) {
          if (index == 0) {
            return;
          } else {
            this.showIcon = --index;
            this.op = 1 - Math.abs(diff) / this.customW;
          }
        } else if (diff < 0) {
          if (index == this.itemLen - 1) {
            return;
          } else {
            if (index + 1 === this.showIndex) {
              this.showIndex += 1;
            }
            this.showIcon = ++index;
            this.op = 1 - Math.abs(diff) / this.customW;
          }
        }
        this.leftDis = this.customW * this.index - diff;
        this.$emit("move-x", "stop", this.lock);
      }
    },
    upSwiper(ev) {
      this.transition = ".4s";
      if (this.moveX == 0) {
        return;
      } else {
        var upT = new Date();
        var diff = this.moveX - this.touchX;
        var speed = diff / (upT - this.touchT);
        if (Math.abs(speed) > 0.6 || diff > this.customW / 2) {
          if (speed < 0 && this.index != this.itemLen - 1) {
            this.index++;
          } else if (speed > 0 && this.index != 0) {
            this.index--;
          }
        } else {
          if (diff > this.customW / 2 && this.index != 0) {
            this.index--;
          } else if (diff < -this.customW / 2 && this.index != this.itemLen - 1) {
            this.index++;
          }
        }
        this.leftDis = this.customW * this.index;
        this.op = 1;
        this.moveX = 0;
        this.showIcon = -1;
        this.lock = true;
        this.$emit("move-x", "go");
      }
    },
    NavItem(index) {
      if (this.showIndex <= index) {
        this.showIndex = index + 1;
      }
      this.index = index;
      this.leftDis = this.customW * this.index;
      var cop = this.$children[index];
      if (cop) {
        if (!cop.pageIndex > 0) {
          if (cop.loadList) {
            cop.loadList(true, false);
          }
        }
      }
    },
    cancel() {
      router.go(-1);
    },
    keepIndex(fullPath, empty) {
      if (empty) {
        this.$store.commit({
          type: "indexNum",
          index: 0,
          address: fullPath
        });
      } else {
        this.$store.commit({
          type: "indexNum",
          index: this.index,
          address: fullPath
        });
      }
    },
    obtainIndex(fullPath) {
      var arr = this.vuexIndex;
      for (var key in arr) {
        if (arr[fullPath]) {
          this.index = arr[fullPath];
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.customW = this.$refs.customBox.getBoundingClientRect().width;
      this.showIndex = this.index + 1;
      this.leftDis = this.index * this.customW;
    });
  },
  created() {
    let itemLen = Object.keys(this.listData.title);
    this.itemLen = itemLen.length;
  },
  activated() {
    //		var from=this.$route.params.from;
    //		if(from!='approve'&&from!='startForm') return;
    //		var childs=this.$children;
    //		if(childs.length==0) return;
    //		var todolist=childs[0].$refs.todolist;
    //		if(todolist){
    //			if(todolist.loadList){
    //				todolist.loadList(true,false);
    //			}
    //		}
  },
  deactivated() {}
};