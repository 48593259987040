/* this file is transformed by vux-loader */
/* eslint-disable */
import Vue from 'vue';
import { loadRefreshList } from '@/assets/app.js';
import { ListTheme, ListOther } from 'vue-ydui';
import listMore from '@/components/common/listMore';
import router from '@/router/index';
import instPanelStyle from './instPanelStyle';
import instStatusColor from '@/assets/instStatusColor.js';
Vue.component(listMore.name, listMore);
Vue.component(instPanelStyle.name, instPanelStyle);
export default {
  name: 'attend-list',
  data() {
    return {
      list: [],
      pageIndex: 0,
      pageSize: 20,
      total: 0
    };
  },
  props: {
    index: [String, Number]
  },
  computed: {
    treeIds() {
      return this.$store.state.searchModule.instSearchTreeIds;
    },
    searchVal() {
      return this.$store.state.searchModule.instSearchVal;
    }
  },
  watch: {
    treeIds: function (val, oldVal) {
      if (this.$parent.index == this.index) {
        this.loadList(true, false);
      }
    },
    searchVal: function (val, oldVal) {
      if (this.$parent.index == this.index) {
        this.loadList(true, false);
      }
    }
  },
  created() {
    this.loadList(true, false);
  },
  methods: {
    refreshList() {
      this.loadList(true, false);
    },
    loadList(initPage, merge) {
      var url = _baseUrl + "/mobile/bpm/myAttendsData.do";
      if (initPage) {
        this.pageIndex = 0;
      } else {
        this.pageIndex++;
      }
      var params = "pageIndex=" + this.pageIndex + "&pageSize=" + this.pageSize;
      params = this.buildQuery(params);
      loadRefreshList(url, params, this, data => {
        this.total = data.total;
      }, merge);
    },
    buildQuery(params) {
      var treeIds = this.$store.state.searchModule.instSearchTreeIds;
      if (treeIds) {
        params += "&treeIds=" + treeIds;
      }
      var searchVal = this.$store.state.searchModule.instSearchVal;
      if (searchVal) {
        params += "&searchVal=" + searchVal;
      }
      return params;
    },
    getStatusHtml(status) {
      return this.getStyByStatus(status);
    },
    getStyByStatus(status) {
      var str = "";
      var sts = instStatusColor.instStatusColor[status];
      if (sts) {
        var color = sts.color;
        var background = sts.background;
        var name = sts.name;
        str = "<span class='inst-list-status' style='color:" + color + ";background:" + background + "'>" + name + "</span>";
      } else {
        str = "<span class='inst-list-status'>" + status + "</span>";
      }
      return str;
    }
  }
};